@import "./_variable";
.text-main-black {
  color: $divider-dark;
}
.text-lightgray {
  color: $lightgray-color;
}
.text-lightgray-iux {
  color: $grey3-color;
}
.text-lightdark {
  color: #b3b3b3;
}
.text-gray {
  color: #8d8d8d;
}
.text-is-error {
  color: #e3673f;
}
.text-black {
  color: #000;
}

.text-of-white {
  color: #d0d0d0;
}
.text-of-champ {
  color: #dcbe59;
}
.text-of-elite {
  color: #d75554;
}
.text-iux {
  color: rgb(157 161 162);
}

.text-primary-new-iux {
  color: $primary-color !important;
}
.text-primary-sub-iux {
  color: $primary-sub-color !important;
}
.text-yellow-new-iux {
  color: #dcbe59 !important;
}

.text-primary-iux {
  color: #009eff;
}
.text-loss-iux {
  color: $loss-color;
}
.text-danger {
  color: #ff4d4f !important;
}
.text-neutral-grey {
  color: $neutral-grey;
}
// .text-black-3 {
//   color: $grey3-color;
// }
