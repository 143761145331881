@import "./variable";

.card-report-dark {
  border-radius: 20px;
  background: #1e1e1e;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  width: 595px;
  height: 334px;
  padding: 24px;
  z-index: -1;
  // position: absolute;
  &.ispreviews {
    scale: 0.45;
    // position: absolute;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
.card-report-light {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  width: 595px;
  height: 334px;
  padding: 24px;
  z-index: -1;
  // position: absolute;
  &.ispreviews {
    scale: 0.45;
    // position: absolute;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.report-datepicker {
  visibility: hidden;
  // display: none;
}

.report-datepicker-backdrop {
  width: 100vw;
  height: 100vh;
  background-color: #1e1e1e;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.2;
  z-index: 99;
}

.ant-picker-cell {
  text-align: center;
  vertical-align: middle;
  .ant-picker-cell-inner {
    width: 95% !important;
    height: 45px !important;
    border-radius: 8px !important;
    padding: 10px !important;
  }
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background-color: #1cb894 !important;
  }
}

.ant-picker-panel-container {
  padding: 20px;
  border-radius: 20px !important;
}

.container-datepicker {
  top: 30%;
  position: absolute;
  right: 60%;
  transform: translate(-50%, -50%);
}

// .ant-picker-dropdown {
//   position: fixed !important;
//   top: 50% !important;
//   left: 50% !important;
//   transform: translate(-50%, -50%) !important;
// }
.custom-calendar {
  position: fixed;
  z-index: 1;
  right: 50%;
}
@media only screen and (max-width: 575px) {
  .custom-calendar {
    position: fixed;
    z-index: 1;
    right: 52%;
  }
  .report-title-card {
    font-size: 10px !important;
  }

  .report-price-card {
    font-size: 12px !important;
  }

  .report-sum-card {
    font-size: 10px !important;
  }
}
.container-tooltip {
  background: transparent;
}
.box-tooltip {
  border-radius: 16px 16px 0px 0px;
  background: #d9d9d9;
  padding: 10px 16px;
}
.box-tooltip-detail {
  padding: 16px;
  border-radius: 0px 0px 16px 16px;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
}
.box-img-shadow {
  &-light {
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  }
  &-dark {
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  }
}
