.ant-menu {
  border-inline-end:none !important;
  background-color: #00000000 !important;
}

.ant-drawer-content {
  background-color: white;
}

.text-white {
  color: white !important;
}
.text-white-hover {
  color: white;
}
.text-white-hover:hover {
  color: #1cb894 !important;
}
.nav-dropdown {
  background: #00000070;
  color: white;
}

#nav-platforms > .ant-menu {
  /* background: radial-gradient(#004275 15%, #004070 60%); */
  /* background-image: radial-gradient(
    circle farthest-side at center bottom,
    rgb(0 81 161),
    rgb(0, 48, 135) 125%
  ); */
  background-color: black !important;
  line-height: 60px;
  font-size: 14px;
  padding-left: 14%;
}

.ant-menu-horizontal > .ant-menu-item > a {
  color: rgb(217 217 217);
}

.ant-menu-item > .text-white {
  color: white !important;
}

.ant-menu-item > a {
  color: #ffffffa6;
}

.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: white !important;
}

.menuBar {
  padding: 0 25px;
  background-color: #00000000;
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  width: 100%;
  overflow: hidden;
  /* border-bottom: 1px solid rgba(207, 207, 207, 0.267); */
}

.top-manuBar {
  padding: 12px;
}

.bg-signup {
  background-color: #03588c;
}
.logo {
  width: auto;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}
.MenuOutline {
  background-color: #000 !important;
  color: white;
  top: 10px;
  font-size: 20px;
  border: none;
}

.barsMenu {
  top: 15px;
}

.menuCon {
  margin: 0px !important;
  /* position: absolute; */
  right: 25px;
  float: right;
  padding-top: 15px;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  float: right;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  display: none;
  background: none;
  border: 0px;
  font-size: 20px;
}

.barsMenu {
  border-color: white;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: white;
  position: relative;
  margin: 5px;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(16);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  display: none;
}

.ant-menu-horizontal > .ant-menu-item > a:only-child {
  display: inline-block;
  font-size: 14px;
}

@media screen and (max-width: 1024px) {
  .barsMenu {
    display: inline-block;
  }
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
  }
  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  .logo a {
    padding: 10px 20px;
  }
  .ant-menu-horizontal > .ant-menu-item > a:only-child {
    display: inline-block;
    font-size: 11px;
    color: #fff !important;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    width: 100%;
    text-align: left;
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) {
  .hamburger {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 480px) {
  #nav-platforms > .ant-menu {
    /* background: radial-gradient(#004275 15%, #004070 60%); */
    /* background-image: radial-gradient( circle farthest-side at center bottom, rgb(0 81 161), rgb(0, 48, 135) 125% ); */
    background-color: black !important;
    line-height: 60px;
    font-size: 14px;
    padding-left: 0%;
  }
  .logo {
    width: 100%;
    text-align: center;
  }

  .nav-auto-dflex {
    display: flex;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .ant-menu-horizontal > .ant-menu-item > a:only-child {
    display: inline-block;
    font-size: 11px;
    color: #fff !important;
  }
}
