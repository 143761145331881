@import "./_variable";
.noti-container {
  padding: 20px;
  max-height: 600px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  width: 357px;

  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    border-radius: unset;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }
}
.badge-icons-noti {
  &-success {
    background: #1cb89433;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-reject {
    background: #e3673f66;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-progress {
    background: #009eff33;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-read {
    background: #d9d9d9;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-slip {
    background: #1cb89433;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.noti-tabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.noti-selected-tab {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 2px solid $primary-sub-color;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  padding-bottom: 8px;
}

.noti-tab {
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  padding-bottom: 8px;
}

.noti-message {
  margin-top: 20px;
  overflow: scroll;
  flex-grow: 1;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.noti-message::-webkit-scrollbar {
  display: none;
}

.noti-message-container {
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px;
  margin-bottom: 6px;
}
.noti-message-container-unhover {
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px;
  margin-bottom: 6px;
}

.noti-message-container:hover {
  background-color: #f2f4f4;
  cursor: pointer;
  border-radius: 10px;
}

.noti-message-div {
  flex-grow: 1;
  overflow: hidden;
}

.noti-message-icon {
  background-color: #ccecff;
  padding: 4px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noti-message-title {
  font-weight: 600;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.noti-message-descripton {
  font-weight: 400;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $grey3-color;
}

.noti-message-time {
  font-weight: 400;
  font-size: 12px;
  &.read {
    color: #999999;
  }
  &.unread {
    color: $primary-sub-color;
  }
}

.noti-image {
  width: 100%;
  // height: 100px;
  // background-color: #999999;
  border-radius: 8px;
  // margin: auto;
  // margin: 24px;
}

.noto-description {
  font-size: 12px;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.noti-background {
  background-color: #f2f4f4;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 6px;
}
.ant-badge-dot {
  width: 8px !important;
  height: 8px !important;
}
.noti-empty {
  height: 400px;
  @media screen and (max-width: 768px) {
    min-height: 100%;
    height: 100%;
  }
}
.ant-image-preview-wrap {
  z-index: 1080 !important;
}
.ant-image-preview-mask {
  z-index: 1080 !important;
}
.btn-close-noti {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
