.contact-expand {
  right: 110px;
  bottom: 110px;
  z-index: 9999;

  button {
    width: 48px;
    height: 48px;
  }
  .ant-float-btn-group-wrap {
    display: table;
  }
  .ant-float-btn-primary .ant-float-btn-body {
    background-color: #169376;
  }
  .ant-float-btn-group-wrap {
    .ant-float-btn-body {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      aspect-ratio: 1;
    }
  }
  button:focus {
    outline: 0;
  }
  .ant-float-btn-content {
    width: 40px;
  }
  .ant-float-btn-group .ant-float-btn {
    background: transparent;
    box-shadow: none;
  }
  svg {
    position: absolute;
    transform: translate(-50%, -50%);
    fill: #fff;
  }
}

@media screen and (max-width: 450px) {
  .contact-expand {
    right: 26px;
    bottom: 82px;
    z-index: 9999;

    button {
      width: 48px;
      height: 48px;
    }
    .ant-float-btn-group-wrap {
      display: table;
    }
    .ant-float-btn-group-wrap {
      .ant-float-btn-body {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        aspect-ratio: 1;
      }
    }
    button:focus {
      outline: 0;
    }
    .ant-float-btn-content {
      width: 40px;
    }
    .ant-float-btn-group .ant-float-btn {
      background: transparent;
      box-shadow: none;
    }
    svg {
      position: absolute;
      transform: translate(-50%, -50%);
      fill: #fff;
    }
  }
}
