.tutorial-default {
  .slick-dots li {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    background-color: #d9d9d9;
    box-shadow: none !important;
  }
  .slick-dots {
    .slick-active {
      background-color: #1cb894;
    }
    .slick-active > button {
      background: transparent !important;
    }
  }
  .slick-dots li > button {
    background: transparent !important;
  }

  .ant-modal {
    top: 20px;
  }
}

.tutorial-modal {
  top: 20px !important;
  @media (max-width: 767px) {
    .ant-modal-content {
      max-height: none;
    }
  }
  .ant-modal-content {
    height: 100vh;
    max-height: 635px;
  }

  .ant-modal-footer {
    display: none;
  }
}
