.trader-fair-singapore {
  .button-d {
    text-align: end;
  }

  .header {
    z-index: 1;
    position: relative;
    background: #161616;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .inside {
      color: white;
      display: flex;
      align-items: center;
      gap: 24px;
      width: 100%;
      max-width: 1200px;
    }
  }

  .content-p {
    background: #161616;
    max-width: 1000px;
    z-index: 1;
    position: relative;
    margin: auto;
    @media (max-width: 1050px) {
      margin-left: 10px;
      margin-right: 10px;
    }
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 40px;
    border-radius: 12px;
    @media (max-width: 560px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .bg {
    position: fixed;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .title {
    font-size: 24px;
    font-width: 600;
    color: white;
    margin-bottom: 12px;
  }

  .description {
    font-size: 14px;
    font-width: 400;
    color: #a6a6a6;
    margin-bottom: 32px;
  }

  .select-ration {
    margin-bottom: 38px;

    .title-d {
      margin-bottom: 16px;
      color: white;
    }

    ::placeholder {
      color: #A6A6A6;
      opacity: 1;
    }

    .button-d {
      margin: auto;
      display: flex;
      gap: 16px;
      align-items: center;
      background-color: #272727;
      max-width: 400px;
      border-radius: 24px;
      padding: 16px;
      color: white;
      cursor: pointer;
      margin-bottom: 26px;
      border: 1px solid transparent;

      .ratio {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid white
      }

      .ratio-true {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid white;
        background-color: #1CB894;
      }
    }

    .button-d-true {
      margin: auto;
      display: flex;
      gap: 16px;
      align-items: center;
      background-color: #272727;
      max-width: 400px;
      border-radius: 24px;
      padding: 16px;
      color: white;
      cursor: pointer;
      margin-bottom: 26px;
      border: 1px solid #1CB894;

      .ratio {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid white
      }

      .ratio-true {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid white;
        background-color: #1CB894;
      }
    }
  }

  .input-custom {
    margin-bottom: 34px;
    color: white;

    .highlight {
      color: #52AB8C
    }

    ::placeholder {
      color: #A6A6A6;
      opacity: 1;
    }

    .title-d {
      margin-bottom: 16px;
    }
  }

  .select-score {
    margin-bottom: 34px;
    color: white;

    .number {
    }

    .highlight {
      color: #52AB8C
    }

    .score-container {
      display: flex;
      gap: 24px;
      @media (max-width: 420px) {
        gap: 12px;
      }
      justify-content: center;
      margin-top: 20px;
    }

    .score {
      cursor: pointer;
      border: 1px solid white;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white
    }

    .score-true {
      border: 1px solid #1CB894;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1CB894
    }


    .under-score {
      display: flex;
      gap: 188px;
      @media (max-width: 540px) {
        gap: 140px;
        font-size: 12px;
      }
      @media (max-width: 420px) {
        gap: 80px;
        font-size: 12px;
      }
      justify-content: center;
      margin-top: 14px;
      color: #A6A6A6;;
    }
  }

  .success-contain {
    text-align: center;
  }

  .success-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    color: white;
    margin-bottom: 38px;
    margin-top: 88px;
  }

  .success-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    max-width: 600px;
    margin: auto;
    margin-top: 38px;
    margin-bottom: 88px;
  }
}
