@media (max-width: 480px) {
  .menu-active {
    background-color: #d3e4eb26 !important;
    color: $primary-sub-color !important;
    /* border-radius: 6px 0px 0px 6px; */
    font-weight: 500;
    &::after {
      position: absolute;
      top: 0;
      left: 0 !important;
      right: unset !important;
      bottom: 0;
      border-right: 3px solid $primary-sub-color !important;
      transform: scaleY(0.0001);
      opacity: 0;
      transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      content: "";
    }
  }
  .menu-inactive {
    background-color: #d3e4eb00 !important;
    color: rgba(0, 0, 0, 0.65);
    /* border-radius: 6px 0px 0px 6px; */
    font-weight: 500;
  }
}

.report {
  .ant-asdasdasd {
    color: red;
  }
}
.dashboard-container {
  padding: 8px;
  width: 300px;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  // @media screen and (max-width: 768px) {
  //   max-width: 100%;
  //   border-radius: unset;
  //   max-height: 100%;
  //   height: 100%;
  //   width: 100%;
  // }
}
