.iux-radio {
  border: 1px solid #d9d9d9;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.checked {
    border: 1px solid #1cb894;
    & div {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #1cb894;
    }
  }
}
.iux-scan-id {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
.iux-scan {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  &.success {
    background: #1cb89433;
  }
}
.box-reason {
  border-radius: 10px;
  min-height: 132px;
  background: #d9d9d94d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #1cb894 !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #1cb894 !important;
}
/* Camera feedback */
#screen-scanning {
  position: relative;
}

#view-scan-from-camera {
  position: relative;
}

#camera-feedback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
}

#camera-guides {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
}

/* Auxiliary classes */
.hidden {
  display: none !important;
}
#view-landing,
#view-scan-from-camera,
#view-scan-from-file,
#view-results {
  display: block;
  width: 100%;
  height: 100%;
}
#loading {
  display: block;
}
@media screen and (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    width: 100% !important;
    height: 1px !important;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    position: absolute;
    top: 50% !important;
    left: 32px !important;
    width: 100% !important;
    height: 1px !important;
    padding: 0 0px 0 24px !important;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: unset !important;
    min-height: 0 !important;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #1cb894 !important;
  }
}
.verify-w-input {
  width: 400px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
