@import "./_variable";
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $primary-sub-color !important;
}
.nav- {
  a {
    text-decoration: none !important;
  }
  &menu-item {
    color: $white-color !important;
  }

  // &menu-head {
  //   color: $darkgray-color !important;
  // }

  // &menu-head:hover {
  //   color: $primary-color !important;
  // }
}
.menu-hover-item {
  width: 100%;
  :hover {
    background-color: $primary-color;
  }
}
.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
  box-shadow: unset;
  @media screen and (max-width: 425px) {
    background-color: $primary-color !important;
    box-shadow: 0px 0px 12px $primary-color;
  }
}
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: $primary-color;
  box-shadow: 0px 0px 12px $primary-color;
}
.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected),
.ant-menu-light
  .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
  color: $primary-color !important;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
  background-color: transparent;
  box-shadow: unset;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.ant-menu-item::after {
  border-bottom: none;
}
