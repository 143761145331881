$primary-color: #169376;
$primary-sub-color: #1cb894;
$black-color: #000;
$divider-dark: #1e1e1e;
$white-color: #fff;
$bg-gray: #f2f2f2;
$bg-light-gray: #fbfbfb;
$darkwhite-color: #cccccc;
$darkgray-color: #444444;
$grey3-color: #585858;
$badge-disabled: #d9d9d9;
$lightgray-color: #999999;
$bg-footer: #16171d;
$yellow-color: #ae844d;
$error-color: #e3673f;
$loss-color: #f27669;
$neutral-grey: #a3a3a3;
