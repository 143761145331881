.banner-default {
  .slick-list {
    display: flex;
  }
  .slick-track {
    display: flex;
  }
  // @media (max-width: 900px) {
  //   .slick-track {
  //     height: 125px !important;
  //   }
  // }
  // @media (max-width: 900px) {
  //   .slick-track {
  //     height: 140px !important;
  //   }
  // }
  // @media (max-width: 600px) {
  //   .slick-track {
  //     height: 200px !important;
  //   }
  // }
  // @media (min-width: 901px) {
  //   .slick-track {
  //     height: 125px !important;
  //   }
  // }
  // @media (min-width: 1200px) {
  //   .slick-track {
  //     height: 105px !important;
  //   }
  // }

  .slick-slide > div {
    height: 100%;
  }

  .slick-slide > div > div {
    height: 100%;
  }
}
