.white-space-pre-line {
  white-space: pre-line;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.w-xs-auto {
  @media screen and (max-width: 480px) {
    width: auto !important;
  }
}
.ant-list-item {
  border-block-end: none !important;
}
a:hover {
  text-decoration: none;
}
.ant-select-selector {
  height: 48px !important;
}
.ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 48px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 48px !important;
}
.ant-select-single {
  height: 48px;
}
.button-default-w {
  min-width: 163px;
  @media screen and (max-width: 480px) {
    min-width: 112px;
  }
}
.border-divider {
  border-inline-start: 1px solid #d9d9d9;
  border-block-start: 1px solid #d9d9d9;
}
@media (max-width: 720px) {
  .ant-picker-panels {
    flex-direction: column !important;
  }
  .ant-picker-datetime-panel {
    flex-direction: column !important;
  }
  .ant-picker-panel-container {
    // position: absolute;
    padding: 10px !important;
  }
}
.ant-picker-panel-container {
  padding: 10px !important;
}
.ant-table-body::-webkit-scrollbar {
  display: none;
}

.ant-table-body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-modal-content::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ant-modal-content {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.border-gradient-1 {
  position: relative;
  border: none;
  border-radius: 8px;
}
.border-gradient-1::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(45deg, #169376, #393939);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.sm-fs-10 {
  @media screen and (max-width: 480px) {
    font-size: 12px !important;
  }
}

.sm-fs-12 {
  @media screen and (max-width: 480px) {
    font-size: 14px !important;
  }
}

.white-space-break {
  white-space: break-spaces !important;
}

.intercom-lightweight-app-launcher .intercom-launcher {
  transition: none !important;
}

.text-des-withdraw {
  white-space: break-spaces;
  line-break: anywhere;
}
.islotback
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #d9d9d9;
}
