@import "./_variable";
.analyze-report {
  .analyze-profit {
    background-color: #f2f2f2;
  }
  .analyze-loss {
    background-color: #f2f2f2;
  }
  .border-report-card {
    border-radius: 12px 0px 0px 12px;
    overflow: hidden;
    @media screen and (max-width: 480px) {
      border-radius: 12px;
    }
  }
  .border-report-card-right {
    border-radius: 0px 12px 12px 0px;
    overflow: hidden;
    @media screen and (max-width: 480px) {
      border-radius: 12px;
    }
  }
  .profit-price {
    font-size: 16px;
    font-weight: 700;
    color: #1cb894;
  }
  .loss-price {
    font-size: 16px;
    font-weight: 700;
    color: #f27669;
  }
  .btn-outline-export {
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 10px;
    color: black;
    height: 48px;
  }
  .btn-outline-export:hover {
    color: rgb(142, 142, 142);
  }
  .btn-outline-export:focus {
    color: black;
  }
}
.analyze {
  .ant-tooltip-inner {
    background-color: #fff !important;
  }
  .ant-tooltip-arrow::before {
    background-color: #fff !important;
  }
}
.btn-outline-export .ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  border-radius: 10px;
  border: 1px solid $badge-disabled !important;
  background: $badge-disabled !important;
  color: $lightgray-color !important;
}
