.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 38px;
  height: 54px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}
.otp-input:focus-visible {
  outline: #1CB894 auto 1px;
}
.otp-input-error {
  width: 38px;
  height: 54px;
  border: 1px solid #E3673F;
  border-radius: 5px;
  padding: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .otp-input {
    width: 30px;
    height: 42px;
   
  }
  .otp-input-error {
    width: 30px;
    height: 42px;
   
  }
  .otp-group {

    column-gap: 18px;
  }
}