.transaction-history {
  padding: 18px;
  .space {
    margin-bottom: 42px;
  }
  .title-history {
    font-size: 12px;
    font-weight: 300;
  }
  .export-data {
    text-align: end;
    margin-top: 12px;
    font-size: 12px;
    font-weight: 300;
  }
  .export-button {
    font-weight: 400;
    color: #1cb894;
    text-decoration: underline;
    cursor: pointer;
  }
}
