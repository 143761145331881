.email-verify {
  background-color: black;
  .verify-header {
    background-color: black;
    display: flex;
    justify-content: space-between;
    padding: 16px 26px;
  }
  .verify-container {
    background-color: #161616;
    position: absolute;
    top: 140px;
    border-radius: 20px;
    border: 1px solid #6b6b6b;
    padding: 20px;
    padding-top: 80px;
    padding-bottom: 60px;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    max-width: 400px;
    width: 90%;
  }
  .verify-title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 32px;
  }
  .verify-description {
    font-size: 14px;
    font-weight: 400;
    color: #a6a6a6;
    margin-top: 20px;
  }
  .verify-button {
    background-color: #1cb894;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 20px;
    margin-top: 70px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
  }
  .header-verify-button {
    display: inline-block;
    padding: 10px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #1cb894;
    color: #1cb894;
  }
}
