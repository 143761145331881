@import "./variable";

.bg-swap-status {
  background: $bg-light-gray;
  border-radius: 20px;
}

.mw-swap {
  max-width: 780px;
  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}

.nbp-bg-swap-status {
  background: $bg-light-gray;
  border-radius: 20px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
}
.nbp-bg-balance-protection {
  background: $bg-light-gray;
  border-radius: 20px 0px 0px 20px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
}
.nbp-bg-balance-recovertime {
  background: $bg-light-gray;
  border-radius: 0px 20px 20px 0px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
}

.nbp-container-green {
  background-color: #e5f4f1;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.text-1E1E1E {
  color: #1e1e1e;
}

.box-nbp {
  background-color: #fff;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  border: 1px solid $primary-sub-color;
  padding: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-scrollbar-recovertime {
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 12px;
    // background-color: #d9d9d9;
  }
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #d9d9d9;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a3a3a3;
    // outline: 1px solid slategrey;
    border-radius: 12px;
  }
}
